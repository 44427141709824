import { useConnection } from '@solana/wallet-adapter-react';
import { useQuery } from '@tanstack/react-query';
import { DST_PROGRAM_ID } from '../../constants';
import { dstInfoParser, findDSTInfoAddress } from '@thevault/dst';
import { directorParser, findDirectorAddress } from '@thevault/directed-stake';

const blacklist = [
    'sagasJDjjAHND4hien3bbo5xXkzCT5Ss6nKjyUJ45aw',
    'sandyGeU5cX65Hn1dqjxrtmtGFTUgCtxuYzyrghTPYB',
];

export default function useGetDSTList() {
    const { connection } = useConnection();
    return useQuery({
        queryKey: ['dstList'],
        queryFn: async () => {
            // Get info batch call
            const info = (await connection.getProgramAccounts(DST_PROGRAM_ID))
                .map((account) => {
                    const data = dstInfoParser.parse(
                        Buffer.from(account.account.data),
                    );
                    return {
                        address: account.pubkey,
                        data: data,
                    };
                })
                .map((account) => {
                    const dstAddress = findDSTInfoAddress(
                        account.data.tokenMint,
                    );
                    const directorAddress = findDirectorAddress(dstAddress);
                    return { ...account, directorAddress };
                });

            // batch call on director addresses
            const directors = (
                await connection.getMultipleAccountsInfo(
                    info.map((account) => account.directorAddress),
                )
            ).map((account, i) => {
                if (!account) {
                    return undefined;
                }

                const data = directorParser.parse(Buffer.from(account.data));
                return {
                    address: info[i].directorAddress,
                    data: data,
                };
            });

            // Merge arrays
            const merged = info.map((account) => {
                const director = directors.find(
                    (director) =>
                        director?.address.toString() ===
                        account.directorAddress.toString(),
                );
                return { ...account, director: director?.data };
            });

            return merged.filter(
                (dst) =>
                    !blacklist.includes(
                        dst.director?.stakeTarget.toString() ?? '',
                    ),
            );
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        gcTime: 3600 * 1000 * 24,
    });
}
