import { useEffect, useState } from 'react';
import { AppTheme } from '../typings/window';

export const useToggleTheme = (r = false) => {
    const [theme, setTheme] = useState<AppTheme | null>(
        typeof window !== 'undefined' ? window.__theme ?? null : null,
    );

    useEffect(() => {
        if (r) {
            return;
        }
        window.__onThemeChange = () => {
            setTheme(window.__theme ?? null);
        };
    }, []);

    const toggleTheme = (theme: AppTheme) => {
        window.__setPreferredTheme(theme);
    };

    return { theme, toggleTheme };
};
