import { ChainId, Token } from '@saberhq/token-utils';
import { PublicKey } from '@solana/web3.js';
import {
    POINTS_TOKEN,
    QUARRY_CONFIG,
    SBR_LP_TOKEN,
    SBR_REWARDER,
    VSOL_TOKEN_OBJ,
} from '../../../../constants';

export type PoolRewardsInfo = {
    rewarderKey: PublicKey;
    rewardsToken: Token;
    iouMint?: PublicKey;
};

export type PoolQuarryInfo = PoolRewardsInfo & {
    isReplica?: boolean;
    stakedToken: Token;
};

export type PoolInfo = Readonly<{
    stakedToken: Token;
    primaryRewards: PoolRewardsInfo;
    secondaryRewards?: readonly PoolRewardsInfo[];
}>;

const VAULT_REWARDS = {
    rewarderKey: QUARRY_CONFIG.rewarder,
    rewardsToken: POINTS_TOKEN,
};

export const SBR_TOKEN = new Token({
    name: 'Saber Protocol Token',
    symbol: 'SBR',
    logoURI:
        'https://cdn.jsdelivr.net/gh/saber-hq/spl-token-icons@master/icons/101/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1.svg',
    decimals: 6,
    address: 'Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1',
    chainId: 101,
    tags: ['saber-hidden'],
    extensions: {
        website: 'https://saber.so',
        twitter: 'https://twitter.com/saber_hq',
        github: 'https://github.com/saber-hq',
        medium: 'https://blog.saber.so',
        discord: 'https://chat.saber.so',
        serumV3Usdc: 'HXBi8YBwbh4TXF6PjVw81m8Z3Cc4WBofvauj5SBFdgUs',
        coingeckoId: 'saber',
    },
});

const SABER_REWARDS = {
    rewarderKey: SBR_REWARDER,
    rewardsToken: SBR_TOKEN,
    iouMint: new PublicKey('iouQcQBAiEXe6cKLS85zmZxUqaCqBdeHFpqKoSz615u'),
};

export const VSOL_POOL = {
    stakedToken: VSOL_TOKEN_OBJ,
    primaryRewards: VAULT_REWARDS,
};

export const LST_POOLS = [
    {
        stakedToken: new Token({
            name: 'broSOL',
            logoURI:
                'https://gateway.irys.xyz/82d92Y2qjwQEiF3wu2SgGywNqDCNednjM3uBSf3mHBtr',
            address: 'broKrYYAnJ4jHSQVTCf2wmS6i6SR2sDXDDaWrPD2kJJ',
            decimals: 9,
            symbol: 'broSOL',
            chainId: ChainId.MainnetBeta,
        }),
        primaryRewards: VAULT_REWARDS,
    },
    {
        stakedToken: new Token({
            name: 'banditoSOL',
            logoURI:
                'https://gateway.irys.xyz/BEYSXyeSohxkfJp5geN5KHnixyaek8oaDMAayLRhjtqV',
            address: 'band7sdCo7F9ySg2AHg2jStFR4omSFLdLiJoDE3zoS9',
            decimals: 9,
            symbol: 'banditoSOL',
            chainId: ChainId.MainnetBeta,
        }),
        primaryRewards: VAULT_REWARDS,
    },
    {
        stakedToken: new Token({
            name: 'gridSOL',
            logoURI:
                'https://gateway.irys.xyz/ENQWSDNsb3EZsLaq7s7PrA64PfEUUFM8XEq8Q5cAwbJ6',
            address: 'gridBR1TSJcV1JjAEE9g7ouoiVaEgDNT3dhY6n9oKQq',
            decimals: 9,
            symbol: 'gridSOL',
            chainId: ChainId.MainnetBeta,
        }),
        primaryRewards: VAULT_REWARDS,
    },
    {
        stakedToken: new Token({
            name: 'nordicSOL',
            logoURI:
                'https://storage.googleapis.com/nordicstaking/nordicSOL.png',
            address: 'nordicHi2uzn7pMe4wCUzTHQXeU2N42ViDVtTrahTRn',
            decimals: 9,
            symbol: 'nordicSOL',
            chainId: ChainId.MainnetBeta,
        }),
        primaryRewards: VAULT_REWARDS,
    },
];

export const SBR_LP_POOL = {
    stakedToken: SBR_LP_TOKEN,
    primaryRewards: SABER_REWARDS,
    secondaryRewards: [VAULT_REWARDS],
};

export const POOLS: PoolInfo[] = [VSOL_POOL, ...LST_POOLS];
export const LPS: PoolInfo[] = [SBR_LP_POOL];
